import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from "react-query";
import { createGame } from "../../../../services";
import { Slide, toast } from "react-toastify";
import { Select } from "antd";
import { useProfile } from "../../../../contexts";

const CreateModal = ({
  createModal,
  closeModal,
  onSettled,
  currencies,
  languages,
  locales,
  jurisdictions,
  wagers,
  isItemCopied,
  setIsItemCopied,
  selectedItem,
  setSelectedItem,
  organizationId,
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedCurrencies, setSelectedCurrencies] = useState([]);
  const [selectedJurisdictions, setSelectedJurisdictions] = useState([]);
  const [selectedLocales, setSelectedLocales] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedWager, setSelectedWager] = useState({});
  const [gameName, setGameName] = useState("");
  const [apiUrl, setApiUrl] = useState("");
  const [clientUrl, setClientUrl] = useState("");
  const [selectedAvailableWagerSetsId, setSelectedAvailableWagerSetsId] =
    useState([]);

  const initialState = {
    available_rtp: isItemCopied ? selectedItem.available_rtp : [],
    available_volatility: isItemCopied ? selectedItem.available_volatility : [],
    rtp: "",
    volatility: "",
  };

  const [selectedData, setSelectedData] = useState(initialState);
  const [rtp, setRtp] = useState("");
  const [newRtp, setNewRtp] = useState("");
  const [newRtpError, setNewRtpError] = useState("");
  const [newVolatility, setNewVolatility] = useState("");
  const [volatility, setVolatility] = useState("");
  const [volatilityError, setVolatilityError] = useState("");

  const [demo, setDemo] = useState(false);
  const [freespins, setFreespins] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [statisticsShown, setStatisticsShown] = useState(false);
  const [onlineVolatility, setOnlineVolatility] = useState(false);

  const [error, setError] = useState(null);
  const [hasError, setHasError] = useState(false);

  const removeItem = (key) => (indexesToSave) => {
    setSelectedData((prevData) => {
      const updatedItems = prevData[key].filter((_, index) =>
        indexesToSave.includes(index)
      );

      if ("available_rtp" === key && !updatedItems.includes(rtp)) {
        setRtp(updatedItems[0] || "");
        updateSelectedData({ rtp: updatedItems[0] || "" });
      }

      if (
        "available_volatility" === key &&
        !updatedItems.includes(volatility)
      ) {
        setVolatility(updatedItems[0] || "");
        updateSelectedData({ volatility: updatedItems[0] || "" });
      }

      return {
        ...prevData,
        [key]: updatedItems,
      };
    });
  };

  useEffect(() => {
    if (isItemCopied && selectedItem) {
      setSelectedCurrencies(selectedItem.currencies);
      setSelectedJurisdictions(selectedItem.jurisdictions);
      setSelectedLocales(selectedItem.user_locales);
      setSelectedLanguages(selectedItem.languages);
      setSelectedWager(selectedItem.wager_set_id);
      setSelectedAvailableWagerSetsId(selectedItem.available_wager_sets_id);
      setGameName(selectedItem.name);
      setApiUrl(selectedItem.api_url);
      setClientUrl(selectedItem.client_url);
      setDemo(selectedItem.is_demo);
      setFreespins(selectedItem.is_freespins);
      setIsPublic(selectedItem.is_public);
      setStatisticsShown(selectedItem.is_statistic_shown);
      setOnlineVolatility(selectedItem.online_volatility);
      setRtp(selectedItem.rtp);
      setVolatility(selectedItem.volatility);
    }
  }, [isItemCopied, selectedItem]);

  const addNewVolatility = () => {
    const newOptionValue = newVolatility.trim().toLowerCase();

    const validValues = ["low", "low-medium", "medium", "medium-high", "high"];

    if (!validValues.includes(newOptionValue)) {
      setVolatilityError(
        "Invalid volatility value. Please enter 'low', 'medium', 'high', or combinations like 'low-medium', 'medium-high'."
      );
      return;
    }

    if (selectedData.available_volatility.includes(newOptionValue)) {
      setVolatilityError("This volatility value already exists in the list.");
      return;
    }

    const updatedVolatility = [
      ...selectedData.available_volatility,
      newOptionValue,
    ].sort((a, b) => {
      const order = {
        low: 1,
        "low-medium": 2,
        medium: 3,
        "medium-high": 4,
        high: 5,
      };
      return order[a] - order[b];
    });

    setSelectedData((prevData) => ({
      ...prevData,
      available_volatility: updatedVolatility,
    }));

    setNewVolatility("");
    setVolatilityError("");
  };

  const updateSelectedData = (newData) => {
    setSelectedData((prevData) => {
      const updatedData = { ...prevData };

      Object.keys(newData).forEach((key) => {
        if (key === "available_rtp" || key === "available_volatility") {
          updatedData[key] = newData[key]
            .split(",")
            .map((value) => value.trim());
        } else {
          updatedData[key] = newData[key];
        }
      });

      return updatedData;
    });
  };

  const addNewRtp = () => {
    const newOptionValue = parseFloat(newRtp.trim().replace(",", "."));

    if (
      isNaN(newOptionValue) ||
      newOptionValue < 50 ||
      newOptionValue > 100 ||
      !Number.isInteger(newOptionValue)
    ) {
      setNewRtpError("Please enter a valid integer value between 50 and 100.");
      return;
    }

    const newOptionIntValue = parseInt(newOptionValue, 10);

    if (selectedData.available_rtp.includes(newOptionValue)) {
      setNewRtpError("This RTP value already exists in the list.");
      return;
    }

    setSelectedData((prevData) => ({
      ...prevData,
      available_rtp: [...prevData.available_rtp, newOptionIntValue].sort(
        (a, b) => a - b
      ),
    }));
    setNewRtp("");
    setNewRtpError("");
  };

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      api_url: "",
      client_url: "",
      organization_id: organizationId,
      wager_set_id: "",
      currencies: [],
      jurisdictions: [],
      languages: [],
      user_locales: [],
      is_demo: false,
      is_freespins: false,
      is_public: false,
      is_statistic_shown: false,
      online_volatility: false,
      available_wager_sets_id: [],
    },
  });

  const { mutate } = useMutation(createGame, {
    onSuccess: () => {
      toastAlert("success");
      onSettled();
      setLoading(false);
      closeModal("createModal");
    },
    onError: (error) => {
      setError(error.response.data.data);
      setHasError(true);
      setLoading(false);
      closeModal("createModal");
    },
  });

  const toastAlert = (type) =>
    toast.success(
      <p className="text-white tx-16 mb-0 ">
        {type === "success"
          ? `Game was created successfully`
          : `Error: ${error}`}
      </p>,
      {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: false,
        transition: Slide,
        autoClose: 2000,
        theme: "colored",
        className: type === "success" ? "bg-primary" : "bg-danger",
        type,
      }
    );

  const onSubmit = handleSubmit((formData) => {
    formData.currencies = selectedCurrencies;
    formData.jurisdictions = selectedJurisdictions;
    formData.user_locales = selectedLocales;
    formData.languages = selectedLanguages;
    formData.wager_set_id = selectedWager;
    formData.is_demo = demo;
    formData.is_freespins = freespins;
    formData.name = gameName;
    formData.is_public = isPublic;
    formData.is_statistic_shown = statisticsShown;
    formData.online_volatility = onlineVolatility;
    formData.available_rtp = selectedData?.available_rtp;
    formData.available_volatility = selectedData?.available_volatility;
    formData.rtp = selectedData?.rtp ? parseFloat(selectedData.rtp) : null;
    formData.volatility = selectedData?.volatility;
    formData.available_wager_sets_id = selectedAvailableWagerSetsId;

    if (selectedData?.rtp && isNaN(formData.rtp)) {
      setError("Invalid RTP value. Please enter a valid number.");
      setHasError(true);
      return;
    }

    setIsItemCopied(false);
    setSelectedItem(null);
    setLoading(true);
    mutate(formData);
    reset();
    resetForm();
  });

  const resetForm = () => {
    setSelectedData(initialState);
    setRtp("");
    setNewRtp("");
    setVolatility("");
    setNewVolatility("");
    setNewRtpError("");
    setVolatilityError("");
    setOnlineVolatility(false);
    setStatisticsShown(false);
    setIsPublic(false);
    setFreespins(false);
    setDemo(false);
    setSelectedAvailableWagerSetsId([]);
    setSelectedWager(null);
  };

  useEffect(() => {
    if (hasError) {
      toastAlert("error");
      setHasError(false);
    }
  }, [error, hasError]);

  useEffect(() => {
    reset((prevValues) => ({
      ...prevValues,
      organization_id: organizationId,
    }));
  }, [organizationId, reset]);

  useEffect(() => {
    if (selectedAvailableWagerSetsId.length === 0) {
      setSelectedWager(null);
      return;
    }

    if (!selectedAvailableWagerSetsId.includes(selectedWager)) {
      setSelectedWager(selectedAvailableWagerSetsId[0] || null);
    }
  }, [selectedAvailableWagerSetsId]);

  return (
    <Modal
      size="lg"
      show={createModal}
      aria-labelledby="example-modal-sizes-title-sm"
      centered
    >
      <Modal.Header>
        <Modal.Title>Game creation</Modal.Title>
        <Button
          variant=""
          className="btn btn-close ms-auto"
          onClick={() => {
            closeModal("createModal");
            setIsItemCopied(false);
            setSelectedItem(null);
            reset();
            resetForm();
          }}
        >
          x
        </Button>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Modal.Body>
            <Container fluid>
              <div className="wd-100p align-items-end d-flex flex-lg-wrap">
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip className="tooltip tooltip-primary1">
                      You can provide your own locales, jurisdictions and
                      languages. Please, provide them in this format. Languages:
                      en, it, etc. Locales: en_US, fr_Fr, etc. Jurisdictions:
                      en, it, etc.
                    </Tooltip>
                  }
                >
                  <i className="fe fe-info cursor-pointer text-muted mg-l-auto"></i>
                </OverlayTrigger>
              </div>
              <Form
                className={`wd-100p needs-validation${isSubmitted ? "" : ""}`}
                onSubmit={handleSubmit(onSubmit)}
              >
                <Row>
                  <Col lg={6}>
                    <Form.Group className="form-group">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        placeholder="Enter name"
                        type="text"
                        {...register("name", {
                          required: gameName ? false : true, // Если gameName не пустой, отключаем required
                          validate: (value) =>
                            gameName ? true : value?.trim() !== "", // Если gameName не пустой, валидация всегда проходит
                        })}
                        isInvalid={!!errors?.name}
                        onChange={(e) => {
                          setGameName(e.target.value);
                        }}
                        isValid={isSubmitted && !errors?.name}
                        value={gameName}
                      />
                      {errors?.name && (
                        <Form.Control.Feedback type="invalid">
                          Name is required
                        </Form.Control.Feedback>
                      )}
                      <Form.Label>API URL</Form.Label>
                      <Form.Control
                        placeholder="Enter url"
                        type="text"
                        {...register("api_url", {})}
                        value={apiUrl}
                        onChange={(e) => {
                          setApiUrl(e.target.value);
                        }}
                      />
                      <Form.Label>Client URL</Form.Label>
                      <Form.Control
                        placeholder="Enter client url"
                        type="text"
                        {...register("client_url", {})}
                        value={clientUrl}
                        onChange={(e) => {
                          setClientUrl(e.target.value);
                        }}
                      />
                      <Form.Label>Available wager set</Form.Label>
                      <Controller
                        name="available_wager_sets_id"
                        control={control}
                        rules={{
                          required: !selectedAvailableWagerSetsId.length
                            ? "Available wager set is required"
                            : false,
                        }}
                        render={({ field, fieldState }) => (
                          <Select
                            {...field}
                            showSearch
                            mode="tags"
                            className={`form-control mr-0 ${
                              fieldState.error ? "is-invalid" : ""
                            }`}
                            optionFilterProp="label"
                            maxTagCount={1}
                            popupClassName={"custom-dropdown"}
                            dropdownStyle={{
                              zIndex: "9999999999",
                            }}
                            options={wagers}
                            placeholder="Select available wager set to add..."
                            onChange={(value) => {
                              field.onChange(value);
                              setSelectedAvailableWagerSetsId(value);
                            }}
                            value={
                              isItemCopied
                                ? selectedAvailableWagerSetsId
                                : field.value || []
                            }
                          />
                        )}
                      />
                      {errors.available_wager_sets_id && (
                        <div className="invalid-feedback-show">
                          {errors.available_wager_sets_id.message}
                        </div>
                      )}
                      <Form.Label>Wager set</Form.Label>
                      <Controller
                        name="wagerSet"
                        control={control}
                        // rules={{required: 'Wager set is required'}}
                        render={({ field, fieldState }) => (
                          <Select
                            // {...field}
                            value={selectedWager}
                            // value={selectedWager?.length > 0 ? selectedWager : null}
                            showSearch
                            className={`form-control mr-0 ${
                              fieldState.error ? "is-invalid" : ""
                            }`}
                            // className={`form-control ${errors.wagerSet ? 'is-invalid' : ''}`}
                            optionFilterProp="label"
                            popupClassName={"custom-dropdown"}
                            dropdownStyle={{
                              zIndex: "9999999999",
                            }}
                            // options={wagers}
                            options={wagers.filter((wager) =>
                              selectedAvailableWagerSetsId.includes(wager.value)
                            )}
                            placeholder="Select wager set..."
                            onChange={(value) => {
                              field.onChange(value);
                              setSelectedWager(value);
                            }}
                          />
                        )}
                      />
                      {/* {errors.wagerSet && (
                                                <div className="invalid-feedback">{errors.wagerSet.message}</div>
                                            )} */}
                      <Form.Label>Add Available RTP</Form.Label>
                      <div className="d-flex flex-row">
                        <Form.Control
                          placeholder="Add new RTP ..."
                          className={`${
                            newRtpError ? "is-invalid" : ""
                          } mg-r-15`}
                          type="text"
                          value={newRtp}
                          onClick={() => newRtpError && setNewRtpError("")}
                          onChange={(e) => setNewRtp(e.target.value)}
                        />
                        <Button className="btn btn-primary" onClick={addNewRtp}>
                          Add
                        </Button>
                      </div>
                      {newRtpError && (
                        <div className="invalid-feedback-show">
                          {newRtpError}
                        </div>
                      )}

                      <Form.Label>Available RTP</Form.Label>
                      <Select
                        mode="multiple"
                        className="form-control mr-0"
                        optionFilterProp="children"
                        dropdownStyle={{
                          zIndex: "9999999999",
                        }}
                        value={
                          selectedData?.available_rtp.length
                            ? selectedData?.available_rtp.map((rtp, index) => ({
                                label: rtp,
                                key: index,
                              }))
                            : []
                        }
                        options={selectedData?.available_rtp.map((rtp) => ({
                          label: rtp,
                        }))}
                        onChange={removeItem("available_rtp")}
                      />

                      <Form.Label>RTP</Form.Label>
                      <Select
                        mode="single"
                        className="form-control mr-0"
                        optionFilterProp="children"
                        value={rtp}
                        dropdownStyle={{
                          zIndex: "9999999999",
                        }}
                        options={selectedData?.available_rtp.map((option) => ({
                          label: option,
                          value: option,
                        }))}
                        placeholder="Select RTP ..."
                        onChange={(value) => {
                          updateSelectedData({ rtp: value });
                          setRtp(value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group">
                      <Form.Label>Currencies</Form.Label>
                      <Controller
                        name="currencies"
                        control={control}
                        // rules={{ required: "Currencies are required" }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            mode="tags"
                            showSearch
                            className={`form-control ${
                              errors.currencies ? "is-invalid" : ""
                            }`}
                            optionFilterProp="label"
                            maxTagCount={4}
                            dropdownStyle={{
                              zIndex: "9999999999",
                            }}
                            options={currencies}
                            placeholder="Select currencies to add..."
                            onChange={(value) => {
                              setSelectedCurrencies(value);
                              field.onChange(value);
                            }}
                            value={
                              isItemCopied
                                ? selectedCurrencies
                                : field.value || []
                            }
                          />
                        )}
                      />
                      {errors.currencies && (
                        <div className="invalid-feedback">
                          {errors.currencies.message}
                        </div>
                      )}

                      <Form.Label>Jurisdictions</Form.Label>
                      <Controller
                        name="jurisdictions"
                        control={control}
                        // rules={{ required: "Jurisdictions are required" }}
                        render={({ field }) => (
                          <>
                            <Select
                              {...field}
                              mode="tags"
                              showSearch
                              className={`form-control ${
                                errors.jurisdictions ? "is-invalid" : ""
                              }`}
                              optionFilterProp="label"
                              maxTagCount={4}
                              popupClassName={"custom-dropdown"}
                              dropdownStyle={{
                                zIndex: "9999999999",
                              }}
                              options={jurisdictions}
                              placeholder="Select jurisdictions to add..."
                              onChange={(value) => {
                                setSelectedJurisdictions(value);
                                field.onChange(value);
                              }}
                              value={
                                isItemCopied
                                  ? selectedJurisdictions
                                  : field.value || []
                              }
                            />
                            {errors.jurisdictions && (
                              <div className="invalid-feedback">
                                {errors.jurisdictions.message}
                              </div>
                            )}
                          </>
                        )}
                      />

                      <Form.Label>Locales</Form.Label>
                      <Controller
                        name="user_locales"
                        control={control}
                        // rules={{ required: "Locales are required" }}
                        render={({ field }) => (
                          <>
                            <Select
                              {...field}
                              mode="tags"
                              showSearch
                              className={`form-control ${
                                errors.user_locales ? "is-invalid" : ""
                              }`}
                              optionFilterProp="label"
                              maxTagCount={4}
                              popupClassName={"custom-dropdown"}
                              dropdownStyle={{
                                zIndex: "9999999999",
                              }}
                              options={locales}
                              placeholder="Select locales to add..."
                              onChange={(value) => {
                                setSelectedLocales(value);
                                field.onChange(value);
                              }}
                              value={
                                isItemCopied
                                  ? selectedLocales
                                  : field.value || []
                              }
                            />
                            {errors.user_locales && (
                              <div className="invalid-feedback">
                                {errors.user_locales.message}
                              </div>
                            )}
                          </>
                        )}
                      />
                      <Form.Label>Languages</Form.Label>
                      <Controller
                        name="languages"
                        control={control}
                        // rules={{ required: "Languages are required" }}
                        render={({ field }) => (
                          <>
                            <Select
                              {...field}
                              mode="tags"
                              showSearch
                              className={`form-control ${
                                errors.languages ? "is-invalid" : ""
                              }`}
                              optionFilterProp="label"
                              maxTagCount={4}
                              dropdownStyle={{
                                zIndex: "9999999999",
                              }}
                              options={languages}
                              placeholder="Select languages to add..."
                              onChange={(value) => {
                                setSelectedLanguages(value);
                                field.onChange(value);
                              }}
                              value={
                                isItemCopied
                                  ? selectedLanguages
                                  : field.value || []
                              }
                            />
                            {errors.languages && (
                              <div className="invalid-feedback">
                                {errors.languages.message}
                              </div>
                            )}
                          </>
                        )}
                      />
                      <Form.Label>Add volatility</Form.Label>
                      <div className="d-flex flex-row">
                        <Form.Control
                          placeholder="Add new volatility ..."
                          className={`${
                            volatilityError ? "is-invalid" : ""
                          } mg-r-15`}
                          type="text"
                          value={newVolatility}
                          onClick={() =>
                            volatilityError && setVolatilityError("")
                          }
                          onChange={(e) => setNewVolatility(e.target.value)}
                        />
                        <Button
                          className="btn btn-primary"
                          onClick={addNewVolatility}
                        >
                          Add
                        </Button>
                      </div>
                      {volatilityError && (
                        <div className="invalid-feedback-show">
                          {volatilityError}
                        </div>
                      )}
                      <Form.Label>Available volatility</Form.Label>
                      <Select
                        mode="multiple"
                        className="form-control mr-0"
                        optionFilterProp="children"
                        dropdownStyle={{
                          zIndex: "9999999999",
                        }}
                        value={
                          selectedData?.available_volatility?.length
                            ? selectedData?.available_volatility.map(
                                (volatility, index) => ({
                                  label: volatility,
                                  key: index,
                                })
                              )
                            : []
                        }
                        options={selectedData?.available_volatility?.map(
                          (volatility) => ({ label: volatility })
                        )}
                        onChange={removeItem("available_volatility")}
                      />
                      <Form.Label>Volatility</Form.Label>
                      <Select
                        mode="single"
                        className="form-control mr-0"
                        optionFilterProp="children"
                        value={volatility}
                        dropdownStyle={{
                          zIndex: "9999999999",
                        }}
                        options={selectedData?.available_volatility?.map(
                          (option) => ({ label: option, value: option })
                        )}
                        placeholder="Select volatility ..."
                        onChange={(value) => {
                          updateSelectedData({ volatility: value });
                          setVolatility(value);
                        }}
                      />
                    </Form.Group>
                    <Row className="mg-t-25">
                      <Form.Label className="mg-r-30 mg-l-30 custom-control custom-checkbox custom-control-md">
                        <Form.Control
                          type="checkbox"
                          className="custom-control-input"
                          name="example-checkbox1"
                          checked={demo}
                          onChange={() => setDemo(!demo)}
                        />
                        <span className="custom-control-label custom-control-label-md tx-17">
                          Demo
                        </span>
                      </Form.Label>
                      <Form.Label className="custom-control custom-checkbox custom-control-md">
                        <Form.Control
                          type="checkbox"
                          className="custom-control-input"
                          name="example-checkbox2"
                          value="option2"
                          checked={freespins}
                          onChange={() => setFreespins(!freespins)}
                        />
                        <span className="custom-control-label custom-control-label-md tx-17">
                          Free spins
                        </span>
                      </Form.Label>
                      <Form.Label className="custom-control custom-checkbox custom-control-md pd-l-55">
                        <Form.Control
                          type="checkbox"
                          className="custom-control-input"
                          name="online_volatility"
                          checked={onlineVolatility}
                          onChange={() =>
                            setOnlineVolatility(!onlineVolatility)
                          }
                        />
                        <span className="custom-control-label custom-control-label-md tx-17">
                          Online volatility
                        </span>
                      </Form.Label>
                      <Form.Label className="custom-control custom-checkbox custom-control-md">
                        <Form.Control
                          type="checkbox"
                          className="custom-control-input"
                          name="example-checkbox1"
                          value="option1"
                          checked={isPublic}
                          onChange={() => setIsPublic(!isPublic)}
                        />
                        <span className="mg-r-30 mg-l-30 custom-control-label custom-control-label-md tx-17">
                          Public
                        </span>
                      </Form.Label>
                      <Form.Label className="custom-control custom-checkbox custom-control-md">
                        <Form.Control
                          type="checkbox"
                          className="custom-control-input"
                          name="example-checkbox2"
                          value="option2"
                          checked={statisticsShown}
                          onChange={() => setStatisticsShown(!statisticsShown)}
                        />
                        <span className="mg-r-30 mg-l-30 custom-control-label custom-control-label-md tx-17">
                          Show statistics
                        </span>
                      </Form.Label>
                    </Row>
                  </Col>
                </Row>
                <Modal.Footer className="mg-t-100">
                  <Button disabled={loading} variant="primary" type="submit">
                    Save
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      closeModal("createModal");
                      setIsItemCopied(false);
                      setSelectedItem(null);
                      reset();
                      resetForm();
                    }}
                  >
                    Cancel
                  </Button>
                </Modal.Footer>
              </Form>
            </Container>
          </Modal.Body>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreateModal;
